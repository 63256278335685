.overflow {
  overflow: unset;
}

.dark_container input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1000px #201F2D !important;
  -webkit-text-fill-color: #fff;
}

.dark_container input:-internal-autofill-selected:focus {
  box-shadow: inset 0 0 0 1000px #201F2D !important;
  -webkit-text-fill-color: #fff;
}

.dark_container input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #201F2D !important;
  -webkit-text-fill-color: #fff;
}

.dark_container input:-webkit-autofill:focus {
  box-shadow: inset 0 0 0 1000px #201F2D !important;
  -webkit-text-fill-color: #fff;
}