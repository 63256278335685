.modal :global .ant-form-item-explain-error{
    text-align: left;
}

.modal .button{
    border-radius: 8px !important;
    width: 100%;
    height: 48px;
    font-size: 18px;
    font-weight: 600;
}
.modal .button2{
    border-radius: 8px !important;
    margin-top: 28px;
    width: 100%;
    height: 48px;
    font-size: 18px;
    font-weight: 600;
}


.main {
    text-align: center;
}

.main .title {
    opacity: 0.8;
    font-family: DINPro-Bold;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
}

.main .subTitle {
    font-family: DINPro-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #6F40EE;
}

.main .subTitle2 {
    font-family: DINPro-Regular;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.80);
    margin-bottom: 30px;
}

.main .processBar {
    padding: 0 18px;
}
.main .progressBars {
    padding: 0 32px;
}

.main .mailtip{
    margin-top: 2px;
    margin-bottom: 41px;
    text-align: left;
}

.main .mailtip .tipOne{
    opacity: 0.8;
    font-family: DINPro-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}
.main .mailtip .tipTwo{
    opacity: 0.8;
    font-family: DINPro-Medium;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
}

.stepOne{
  display: flex;
  justify-content: space-between;
  /* margin-top: 31px; */
}

.stepOne :global .ant-row:first-child{
  margin-right: 0.83vw;
}
.stepOne :global .ant-row:first-child .ant-btn-default{
  border-radius: 8px;
}
.stepOne :global .ant-row:nth-child(2){
  flex-grow: 1;
}

.sfmobilemain{
    display: none;
}

.saveBtn {
  height: 32px !important;
  font-size: 14px !important;
  width: 72px !important;
}
.relateLabel {
  display: inline-block;
  width: 60px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'DINPro';
  color: #696F7F;
  margin-right: 12px;
  text-align: left;
}

/* .mobilegoogleverifyContent :global .ant-btn{
    width: 72px !important;
    height: 32px !important;
} */
.confimcontent .line{
    margin-top: 32px;
    text-align: left;
}

.confimcontent .line3{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}
.confimcontent .line2{
    text-align: left;
}

.confimcontent .line3 :global .ant-btn{
    width: 180px;
    height: 48px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    
}

@media screen and (max-width: 960px){
     .modal .button{
        border-radius: 8px !important;
        width: 100%;
        height: 44px !important;
        font-size: 18px;
        font-weight: 600;
    }
    .modal .button2{
        height: 44px;
        margin-top: 18px;
        font-size: 14px;
    }

    .modal .title{
        display: none;
    }
    .main .processBar {
        padding: 0 18px;
    }
    .main .progressBars {
        padding: 0 32px;
    }
    .main .tipOne{
        display: block;
        text-align: center;
        font-weight: 500 !important;
    }
    .main .tipTwo{
        display: block;
        text-align: center;
        margin-top: 10px;
    }
    .modal .button{
    margin-top: 18px;
    }

    .sfmian .safebuttom{
        height: 32px !important;
    }

    .relate .safebuttom{
        height: 32px !important;
    }


    .mobileContent .relateinput {
        text-align: right;
    }
    .confimcontent .line3 :global .ant-btn{
        max-width: 150px;
        min-width: 40vw;
        height: 48px;
    }
}

@media screen and (min-width: 961px) {
    .loginForm {
        margin-top: 31px;
    }
}