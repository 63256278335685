.modal :global .ant-modal-header{
    display: none ;
}

.modal :global .ant-modal-footer{
    display: none;
}

.modal :global .ant-modal-body{
    padding: 80px 120px;
}

.modal :global .ant-modal {
    width: 640px !important;
}

@media (max-width: 960px) {
    .modal :global .ant-modal-header{
        display: block;
    }
    .modal :global .ant-modal-content{
        border-radius: 12px;
    }
    .modal :global .ant-modal-header{
        height: 44px;
    }
    .modal :global .ant-modal-title{
        line-height: 44px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        font-size: 18px;
        color: #1F2126;
    }
    .modal :global .ant-modal-close-x{
        color: #696F7F;
    }
    .modal :global .ant-modal-body{
        padding: 10px 15px 20px 15px;
    }
    .modal :global .ant-modal-footer{
        display: none;
    }

    .modal :global .ant-modal {
        width: 92% !important;
    }

    .modal :global .NoCaptchaModal {
        color: red;
    }
}