.radioContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  cursor: pointer;
}

.radioImage {
  height: 14px;
  width: 14px;
  flex-shrink: 0;
}

.radioText {
	color: rgba(0, 0, 0, 0.5);
	font-size: 14px;
	line-height: 24px;
	margin-left: 8px;
	text-align: center;
}
/** 深色模式 */
.dark_radioText {
	color: #C8CAD4;
}

.radioLink {
	color: #000;
}

/** 深色模式 */
.dark_radioLink {
  color: #C8CAD4;
}

.radioLink:hover,.radioInnerText:hover{
  color: #6F40EE;
}
/** 深色模式 */
.dark_radioLink:hover,.dark_radioInnerText:hover{
  color: #C8CAD4;

}

.radioInnerText {
	color: #000;
	text-decoration: underline;
}
/** 深色模式 */
.dark_radioInnerText {
	color: #C8CAD4;
}

.radioInnerText:hover {
	color: #6F40EE
}
/** 深色模式 */
.dark_radioInnerText:hover {
	color: #C8CAD4
}

  html[lang='en'] .radioText {
    text-align: left;
  }
  html[lang='en'] .radioContent {
    align-items: flex-start !important;
  }
  html[lang='en'] .radioImage {
    padding-top: 6px;
  }

@media (max-width: 960px) {
  .radioImage {
    height: 14px;
    width: 14px;
  }
}