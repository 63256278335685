.container {
  flex: 1;
}

.content {
  width: 400px;
  margin-left: 63.89vw;
  margin-top: calc((100vh - 440px)/2);
  /* position: fixed; */
}
.title {
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);

}
.subTitle {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 32px;
}

.loginLink,
.loginLink:hover {
  color: #6F40EE;
  font-weight: bold;
}

.otherLoginBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.otherLoginLine {
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 0.5px;
  margin: 0 10px;
}

.otherLoginText {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 17px;
}
.oAuthLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.useLink,
.useLink:hover {
	color: #000000;
}
.flexRow {
	display: flex;
}
.countryCode {
	margin-right: 12px;
}
.mobile {
	flex: 1;
}

.overflow {
  overflow: unset;
}

@media (max-width: 960px) {
  .content {
    margin:  56px auto 0;
    width: 100%;
    padding: 0 15px;
  }
  .title {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
  }
  .subTitle {
    margin-top: 6px;
    margin-bottom: 25px;
    text-align: center;
  }
  .countryCode {
		margin-right: 10px;
	}
  .oAuthLogin {
    padding-bottom: 48px;
  }
}

/* @media (height <= 590px) {
  .content {
    position: inherit;
  }
} */

@media only screen and (min-width:961px) and (max-width:1200px){
  .content {
    margin-left: calc((100vw - 500px - 400px)/2 + 500px);
    margin-top: 224px;
    width: 400px;
  }

}
@media only screen and (min-width:1201px) and (max-width:1439px){
  .content {
    margin-left: calc((100vw - 650px - 400px)/2 + 650px);
    margin-top: 224px;
    width: 400px;
  }
  
}


