.modal :global .ant-form-item-explain-error {
    text-align: left;
}

.sfmian {
    height: 100%;
    background: white;
    padding: 32px 64px 32px 64px;
    border-radius: 12px;
    /* min-width: 900px; */
}

.sfmian :global .ant-list-item-meta {
    align-items: center;
}

.sfmian :global .ant-list-item-meta-avatar {
    height: 28px;
    margin-right: 12px;
}

.sfmian :global .ant-list-item {
    padding: 0;
    border: none;
    height: 80px;
}

/* .sfmian :global .ant-list-item:last-child {
    border-bottom: 1px solid #DBDBDB;
} */

.sfmian :global .ant-list-item-meta-title {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
}

.sfmian :global .ant-list-item-meta-description {
    font-size: 12px;
    color: #696F7F;
}

.sfmian :global .ant-list-item-action li {
    padding: 0;

}

.sfmian :global .ant-list-item-action {
    margin-left: 107px;
    position: relative;
    /* top: -5px; */
}

/* .modal :global .ant-modal-body{
    padding: 80px 120px;
}
.modal :global .ant-modal-footer{
    display: none;
} */

.modal .button {
    border-radius: 8px !important;
    width: 100%;
    height: 48px;
    font-size: 18px;
    font-weight: 600;
}

.modal .button2 {
    border-radius: 8px !important;
    margin-top: 28px;
    width: 100%;
    height: 48px;
    font-size: 18px;
    font-weight: 600;
}

.googleverifyContent .line {
    display: flex;
    margin-top: 32px;
}

.googleverifyContent .line .num {
    width: 14px;
    height: 31px;
    line-height: 31px;
    font-size: 24px;
    color: #000000;
    display: flex;
    align-content: center;
    justify-content: center;
}

.googleverifyContent .line .title {
    font-size: 14px;
    color: #000000;
    margin: 8px 0px 0 8px;
}

.googleverifyContent .line .img1 {
    margin-top: 8px;
    margin-left: 16px;
}

.googleverifyContent .line .img2 {
    margin-top: 8px;
    margin-left: 24px;
}

.googleverifyContent .line2 {
    margin-top: -8px;
}

.googleverifyContent .line2 .line {
    margin-bottom: 11px;
}

.googleverifyContent .line2 .right {
    display: flex;
}

.googleverifyContent .line2 canvas {
    margin-left: 22px;
}

.googleverifyContent .line2 .codetip {
    white-space: nowrap;
    margin-left: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    text-align: left
}

.googleverifyContent .line2 .codetip .copy {
    display: flex;
    /* width: 286px;  */
    width: 100%;
    height: 40px;
    margin-top: 15px;
    background: #F6F8FB;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    color: #434B5F;
}

.mobilegoogleverifyContent {
    display: none;
}

.googleverifyContent .codeInput {
    margin-top: 15px;
    margin-bottom: 48px;
    /* mar */
    margin-left: 16px;
}

.googleverifyContent .codeInput input {
    border-radius: 8px !important;
    margin-right: 24px !important;
    padding: 0;
}

.relate {
    margin: 0 -32px;
}

.relateinput .Input {
    display: flex;
    height: 48px;
    align-items: center;
    margin-top: 32px;
}

.relateinput .InputEle {
    width: 100%;
}

.relateinput .Input button {
    margin-left: 12px;
}

.main {
    text-align: center;
}

.main .title {
    opacity: 0.8;
    font-size: 28px;
    color: #000000;
}

.main .subTitle {
    font-size: 14px;
    color: #6F40EE;
}

.main .subTitle2 {
    font-size: 14px;
    color: #6F40EE;
    margin-bottom: 30px;
}

.main .processBar {
    padding: 0 18px;
}

.main .progressBars {
    padding: 0 32px;
}

.main .mailtip {
    margin-top: 2px;
    margin-bottom: 41px;
    text-align: left;
}

.main .mailtip .tipOne {
    opacity: 0.8;
    font-size: 14px;
    color: #000000;
}

.main .mailtip .tipTwo {
    opacity: 0.8;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
}

.stepOne {
    display: flex;
    justify-content: space-between;
    /* margin-top: 31px; */
}

/* .stepOne :global .ant-row:first-child {
    margin-right: 0.83vw;
} */

.stepOne :global .ant-row:first-child .ant-btn-default {
    border-radius: 8px;
}

.stepOne :global .ant-row:nth-child(2) {
    flex-grow: 1;
}

.sfmobilemain {
    display: none;
}

.saveBtn {
    height: 32px !important;
    font-size: 14px !important;
    width: 72px !important;
}

.relateLabel {
    display: inline-block;
    width: 70px;
    font-size: 14px;
    color: #696F7F;
    margin-right: 12px;
    text-align: left;
}

/* .mobilegoogleverifyContent :global .ant-btn{
    width: 72px !important;
    height: 32px !important;
} */
.confimcontent .line {
    margin-top: 32px;
    text-align: left;
}

.confimcontent .line3 {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.confimcontent .line2 {
    text-align: left;
}

.confimcontent .line3 :global .ant-btn {
    width: 180px;
    height: 48px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;

}

.confimcontent .cancleButton {
    width: 100%;
    height: 48px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    margin-top: 30px;
}

.marginBottom {
    margin-bottom: 24px;
}

.content {
    color: #1F2126;
}

.listButton {
    width: 72px;
    padding: 0;
}


html[lang="zh-TC"] .listButton>span,
html[lang="zh-CN"] .listButton>span {
    font-family: PingFangSC-Medium;
    font-weight: 500;
}

html[lang='en'] .listButton>span,
html[lang='en'] .listButton>span {
    font-family: DINPro-Medium;
}

html[lang='en'] .listButton>span {
    font-family: DINPro-Medium;
}

html[lang="zh-TC"] .main .title,
html[lang="zh-CN"] .main .title,
html[lang="zh-TC"] .googleverifyContent .line .title,
html[lang="zh-CN"] .googleverifyContent .line .title {
    font-family: PingFangSC-Semibold;
    font-weight: 700;
}

.googleverifyContent .line .num,
.mobilegoogleverifyContent .line span:first-child {
    font-family: DINPro-Bold;
}

html[lang="zh-TC"] .main .subTitle,
html[lang="zh-CN"] .main .subTitle,
html[lang="zh-TC"] .main .subTitle2,
html[lang="zh-CN"] .main .subTitle2,
html[lang="zh-TC"] .main .mailtip .tipOne,
html[lang="zh-CN"] .main .mailtip .tipOne {
    font-family: DINPro-Regular !important;
}

.sfmian :global .ant-list-item-meta-title,
.sfmobilemain :global .ant-list-item-meta-title {
    font-family: PingFangSC-Medium !important;
    font-weight: 500;
}

.enTitle,
.relateLabel {
    font-family: DINPro-Medium !important;
}

.sfmian :global .ant-list-item:last-child .ant-list-item-meta-description {
    font-family: DINPro !important;
}

html[lang='en'] .download .title {
    text-align: left;
    display: inline-block;
    width: 300px;
    flex-wrap: wrap;
}


@media screen and (max-width: 960px) {
    .sfmian {
        display: none;
    }

    .sfmobilemain {
        display: block;
        height: 100%;
        /* background: #FFF; */
        /* padding: 0 15px; */
    }

    .modal .button {
        border-radius: 8px !important;
        width: 100%;
        height: 44px !important;
        font-size: 18px;
        font-weight: 600;
    }

    .modal .button2 {
        height: 44px;
        margin-top: 18px;
        font-size: 14px;
    }

    .sfmobilemain :global .ant-list-item-meta {
        align-items: flex-start;
    }

    .sfmobilemain :global .ant-list-item-meta-avatar {
        height: 28px;
        margin-right: 12px;
    }

    .sfmobilemain :global .ant-list-items .ant-list-item {
        padding: 21px 15px 22px 15px;
        border-image: linear-gradient(to right, transparent 0, transparent 15px, #DBDBDB 15px, #DBDBDB calc(100vw - 15px), transparent calc(100vw - 15px), transparent 100%) 2;
        background: #FFFF;
    }

    /* .sfmobilemain :global .ant-list-item:last-child{
        padding: 23px 0 16px 0;
        border-bottom: 1px solid #DBDBDB;
    } */

    .sfmobilemain :global .ant-list-item-meta-title {
        font-size: 14px;
        color: #000000;
    }
    .sfmobilemain :global .ant-list-item-meta-description {
        font-size: 12px;
        color: #696F7F;
    }


    .sfmobilemain :global .ant-list-item-action li {
        padding: 0;

    }

    .sfmobilemain :global .ant-list-item-extra {
        margin: 0;
    }

    .sfmobilemain .content {
        padding-left: 40px;
    }

    .sfmobilemain :global .ant-btn {
        padding: 6px 22px !important;
    }

    .modal .title {
        display: none;
    }

    .main .processBar {
        padding: 0 18px;
    }

    .main .progressBars {
        padding: 0 32px;
    }

    .main .tipOne {
        display: block;
        text-align: center;
        font-weight: 500 !important;
    }

    .main .tipTwo {
        display: block;
        text-align: center;
        margin-top: 10px;
    }

    .modal .button {
        margin-top: 18px;
    }

    .mobilegoogleverifyContent {
        display: block;
    }

    .googleverifyContent {
        display: none;
    }

    .mobilegoogleverifyContent .line {
        line-height: 29px;
        margin-top: 15px;
        display: flex;
        align-items: center;
    }

    .mobilegoogleverifyContent .img {
        margin: 12px 33px 0 25px;
        display: flex;
        justify-content: space-between;

    }

    .mobilegoogleverifyContent .line span:first-child {
        /* font-family: DINPro; */
        /* font-weight: Bold; */
        font-size: 24px;
        color: #000000;
        margin-right: 8px;
        position: relative;
        top: -3px;
    }

    .mobilegoogleverifyContent .line span:nth-child(2) {
        font-size: 14px;
        color: #000000;
    }

    .mobilegoogleverifyContent .line2 .right {
        display: flex;
        margin-top: 12px;
    }

    .mobilegoogleverifyContent .line2 canvas {
        margin-left: 22px;
    }

    .mobilegoogleverifyContent .line2 .codetip {
        /* white-space: nowrap; */
        width: 206px;
        margin-left: 8px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
        flex-shrink: 0;
        text-align: left;
        margin-top: 34px;
    }

    .mobilegoogleverifyContent .copy {
        display: flex;
        width: 286px;
        height: 40px;
        margin-top: 15px;
        background: #F6F8FB;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        color: #434B5F;
    }

    .sfmian .safebuttom {
        height: 32px !important;
    }

    .sfmobilemain .safebuttom>span {
        font-family: var(--font-Medium) !important;
    }

    .relate .safebuttom {
        height: 32px !important;
    }

    .mobilegoogleverifyContent .codeInput {
        margin: 15px 0 36px 0;
    }

    .mobilegoogleverifyContent .codeInput :global .reactcodeInput {
        margin: 0;
        display: flex;
    }

    .mobilegoogleverifyContent .codeInput :global .reactcodeInput input {
        margin: 0 15px 0 0 !important;
    }

    .mobilegoogleverifyContent :global .reactcodeInput .styles_react-code-input__CRulA {
        display: flex !important;
    }

    .mobileContent .relateinput {
        text-align: right;
    }

    .relate {
        margin: 0;
    }

    .relate :global .ant-btn {
        padding: 6px 22px;
    }

    .relateinput .Input {
        display: block;
        height: auto;
        text-align: right;
        /* align-items: right; */
        margin-top: 0px;
    }

    .relateinput .Input:first-child {
        margin: 21px 0 18px 0;
    }

    .relateinput .Input:nth-child(2) {
        margin-bottom: 18px;
    }

    .relateinput .Input:nth-child(3) {
        margin-bottom: 18px;
    }

    .relateinput .Input button {
        margin-left: 12px;
        /* margin-top: 10px; */
    }

    .confimcontent .line3 :global .ant-btn {
        width: 150px;
        height: 48px;
    }

    .relateinput .Input {
        position: relative;
        height: 76px;
    }

    .relateinput .relateLabelContainer {
        position: absolute;
        top: 0;
        left: 0;
    }

    .relateinput .InputEle {
        position: absolute;
        top: 28px;
        left: 0;
        padding-right: 80px;
        width: 100%;
    }

    .relateinput .saveBtnContainer {
        position: absolute;
        top: 34px;
        right: 0;
    }

    .sfmian :global .ant-list-item-action {
        margin-left: 0;
        /* position: relative; */
        top: 0;
    }
}