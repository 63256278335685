.nationalFlagIcon {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    margin-right: 12px;
}
.nationalFlagIcon .flagImg {
    display: inline-block;
    width: 22px;
    height: 22px;
}
.nationalCode {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
    margin-bottom: 12px;

}
/* 新 */
.optionWrapperClass {
    height: 274px;
    overflow-y: scroll;
    border-radius: 8px;
}
.countrySelect :global(.kiki-select-Popover .ant-popover-inner) {
    box-shadow: 0 2px 24px 0 rgba(200,201,204,0.5);
    border-radius: 4px;
}

/** 深色模式 */
.dark_countrySelect :global(.kiki-select-Popover .ant-popover-inner) {
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.5);
}
.btn {
    height: 48px !important;
    width: 128px !important;
}
.btn:global(.kiki-select__button) {
    background-color: #F6F8FB!important;
    border-color: #F6F8FB !important;
    border-width: 1px;
    box-shadow: none;
}
/** 深色模式 */
.dark_btn:global(.kiki-select__button) {
    background-color: transparent!important;
    border-color: #333444 !important;
}
.btn:global(.kiki-select__button--active) {
    background-color: #fff !important;
    border-color: #6f40ee !important;
    border-width: 1px;
    /* border-color: #F6F8FB !important; */
}
/** 深色模式 */
.dark_btn:global(.kiki-select__button--active) {
    background-color: transparent !important;
    border-color: #333444 !important;
    border-width: 1px;
}
.btn :global(.valueText) {
    display: flex;
    align-items: center;
}
.itemFlag {
    font-size: 16px;
    margin-right: 12px;
}


.flagImg {
    display: inline-block;
    width: 16px;
    height: 20px;
    margin-right: 12px;
    padding-top: 5px;
}

.nationalFlag {
    font-size: 28px;
    margin-right: 0;
    width: 28px;
    padding-top: 0;
}
.itemCode {
    margin-left: 12px;
}
.telephoneCode {
    margin-left: 12px;
    margin-right: 12px;
    font-family: 'DINPro-Medium' !important;
}
.popover {
    width: 400px;
    left: 0 !important;
}

@media (max-width: 960px) {
    .popover {
        width: 345px;
        left: 0 !important;
    }
    .btn {
        height: 44px !important;
    }
    .nationalFlagIcon {
        margin-right: 8px;
        font-size: 20px;
    }
    .nationalFlagIcon .flagImg {
        display: inline-block;
        width: 20px;
        height: 16px;
    }
}

@media (max-width: 370px) {
    .popover {
      width: calc(100vw - 30px);
    }
  }